import { ReactNode, useRef } from 'react';
import cn from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import { Card, Text, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useInView from 'src/hooks/useInView';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

interface AdviceCardProps {
    children: ReactNode;
    name: string;
    button?: ReactNode;
}

const AdviceCard: TranslatedComponent<AdviceCardProps> = ({ children, button, name }) => {
    const isMultipleAdvices = useSelector((state) => state.employerAdvicesWidget.advices?.length > 1);

    const elementSpyRef = useRef<HTMLDivElement>(null);
    const isSent = useRef(false);

    const handleShown = () => {
        if (!isSent.current) {
            Analytics.sendHHEvent('element_shown', {
                elementName: 'advices_widget_card',
                card: name,
            });

            isSent.current = true;
        }
    };

    const allItemsButtonRef = useInView<HTMLDivElement>(handleShown);

    return (
        <div
            ref={elementSpyRef}
            className={cn(styles.cardContainer, { [styles.cardContainerMultiple]: isMultipleAdvices })}
        >
            <Card verticalStretched borderWidth="default" padding={12} borderRadius={24} stretched>
                <div ref={allItemsButtonRef} className={styles.cardContent}>
                    <div className={styles.text}>
                        <Text typography="title-5-semibold">{children}</Text>
                    </div>
                    {button && (
                        <>
                            <VSpacing default={6} />
                            {button}
                        </>
                    )}
                </div>
            </Card>
        </div>
    );
};

export default translation(AdviceCard);
